/* global $*/



/*************************************************************************
 *
 * 				ORIGINAL THEME CODE
 *
 *************************************************************************/

/*
 * Detact Mobile Browser
 */
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
	$('html').addClass('ismobile');
}

$(window).load(function() {
	/* --------------------------------------------------------
     Page Loader
     -----------------------------------------------------------*/
	if (!$('html').hasClass('ismobile')) {
		if ($('.page-loader')[0]) {
			setTimeout(function() {
				$('.page-loader').fadeOut();
			}, 500);

		}
	}
})

$(document).ready(function() {
	/* --------------------------------------------------------
        Layout
    -----------------------------------------------------------*/
	(function() {

		//Get saved layout type from LocalStorage
		var layoutStatus = 1; // localStorage.getItem('ma-layout-status');

		if (!$('#header-2')[0]) { //Make it work only on normal headers
			if (layoutStatus == 1) {
				$('body').addClass('sw-toggled');
				$('#tw-switch').prop('checked', true);
			}
		}

		$('body').on('change', '#toggle-width input:checkbox', function() {
			if ($(this).is(':checked')) {
				setTimeout(function() {
					$('body').addClass('toggled sw-toggled');
					localStorage.setItem('ma-layout-status', 1);
				}, 250);
			} else {
				setTimeout(function() {
					$('body').removeClass('toggled sw-toggled');
					localStorage.setItem('ma-layout-status', 0);
				}, 250);
			}
		});
	})();

	/* --------------------------------------------------------
        Scrollbar
    -----------------------------------------------------------*/
	function scrollBar(selector, theme, mousewheelaxis) {
		$(selector).mCustomScrollbar({
			theme: theme,
			scrollInertia: 100,
			axis: 'yx',
			mouseWheel: {
				enable: true,
				axis: mousewheelaxis,
				preventDefault: true
			}
		});
	}

	if (!$('html').hasClass('ismobile')) {
		//On Custom Class
		if ($('.c-overflow')[0]) {
			scrollBar('.c-overflow', 'minimal-dark', 'y');
		}
	}

	/*
     * Top Search
     */
	(function() {
		$('body').on('click', '#top-search > a', function(e) {
			e.preventDefault();

			$('#header').addClass('search-toggled');
			$('#top-search-wrap input').focus();
		});

		$('body').on('click', '#top-search-close', function(e) {
			e.preventDefault();

			$('#header').removeClass('search-toggled');
		});
	})();

	/*
     * Sidebar
     */
	(function() {
		//Toggle
		$('body').on('click', '#menu-trigger, #chat-trigger, .main-menu li[data-trigger="#sidebar"], .get-more-btn[data-trigger="#sidebar"]', function(e) {
			if (e.target.classList.contains('menu-toggle-sign')) {
				return;
			}
			
			if ($(e.target).closest('.main-menu').length || $(e.target).is('.get-more-btn')) {
				$('#menu-trigger').toggleClass('open');
			} else {
				$(this).toggleClass('open');
				e.preventDefault();
			}


			var x = $(this).data('trigger');
			var $elem, $elem2;

			$(x).toggleClass('toggled');
			

			//Close opened sub-menus
			$('.sub-menu.toggled').not('.active').each(function() {
				$(this).removeClass('toggled');
				$(this).find('ul').hide();
			});

			$('.profile-menu .main-menu').hide();

			if (x == '#sidebar') {

				$elem = '#sidebar';
				$elem2 = '#menu-trigger';

				$('#chat-trigger').removeClass('open');

				if (!$('#chat').hasClass('toggled')) {
					$('#header').toggleClass('sidebar-toggled');
				} else {
					$('#chat').removeClass('toggled');
				}
			}

			if (x == '#chat') {
				$elem = '#chat';
				$elem2 = '#chat-trigger';

				$('#menu-trigger').removeClass('open');

				if (!$('#sidebar').hasClass('toggled')) {
					$('#header').toggleClass('sidebar-toggled');
				} else {
					$('#sidebar').removeClass('toggled');
				}
			}

			//When clicking outside
			if ($('#header').hasClass('sidebar-toggled')) {
				$(document).on('click', function(e) {
					if (($(e.target).closest($elem).length === 0) && ($(e.target).closest($elem2).length === 0)) {
						setTimeout(function() {
							$($elem).removeClass('toggled');
							$('#header').removeClass('sidebar-toggled');
							$($elem2).removeClass('open');
						});
					}
				});
			}
		})

		//Submenu
		// $('body').on('click', '.sub-menu > a', function(e) {
		// 	e.preventDefault();
		// 	$(this).next().slideToggle(200);
		// 	$(this).parent().toggleClass('toggled');
		// });
	})();

	/*
     * Clear Notification
     */
	$('body').on('click', '[data-clear="notification"]', function(e) {
		e.preventDefault();

		var x = $(this).closest('.listview');
		var y = x.find('.lv-item');
		var z = y.size();

		$(this).parent().fadeOut();

		x.find('.list-group').prepend('<i class="grid-loading hide-it"></i>');
		x.find('.grid-loading').fadeIn(1500);

		var w = 0;
		y.each(function() {
			var z = $(this);
			setTimeout(function() {
				z.addClass('animated fadeOutRightBig').delay(1000).queue(function() {
					z.remove();
				});
			}, w += 150);
		})

		//Popup empty message
		setTimeout(function() {
			$('#notifications').addClass('empty');
		}, (z * 150) + 200);
	});

	/*
     * Dropdown Menu
     */
	if ($('.dropdown')[0]) {
		//Propagate
		// $('body').on('click', '.dropdown.open .dropdown-menu', function(e){
		//     e.stopPropagation();
		// });

		$('.dropdown').on('shown.bs.dropdown', function(e) {
			if ($(this).attr('data-animation')) {
				$animArray = [];
				$animation = $(this).data('animation');
				$animArray = $animation.split(',');
				$animationIn = 'animated ' + $animArray[0];
				$animationOut = 'animated ' + $animArray[1];
				$animationDuration = ''
				if (!$animArray[2]) {
					$animationDuration = 500; //if duration is not defined, default is set to 500ms
				} else {
					$animationDuration = $animArray[2];
				}

				$(this).find('.dropdown-menu').removeClass($animationOut)
				$(this).find('.dropdown-menu').addClass($animationIn);
			}
		});

		$('.dropdown').on('hide.bs.dropdown', function(e) {
			if ($(this).attr('data-animation')) {
				e.preventDefault();
				$this = $(this);
				$dropdownMenu = $this.find('.dropdown-menu');

				$dropdownMenu.addClass($animationOut);
				setTimeout(function() {
					$this.removeClass('open')

				}, $animationDuration);
			}
		});
	}

	/*
     * Calendar Widget
     */
	if ($('#calendar-widget')[0]) {
		(function() {
			$('#calendar-widget').fullCalendar({
				contentHeight: 'auto',
				theme: true,
				header: {
					right: '',
					center: 'prev, title, next',
					left: ''
				},
				defaultDate: '2014-06-12',
				editable: true,
				events: [
					{
						title: 'All Day',
						start: '2014-06-01',
						className: 'bgm-cyan'
					}, {
						title: 'Long Event',
						start: '2014-06-07',
						end: '2014-06-10',
						className: 'bgm-orange'
					}, {
						id: 999,
						title: 'Repeat',
						start: '2014-06-09',
						className: 'bgm-lightgreen'
					}, {
						id: 999,
						title: 'Repeat',
						start: '2014-06-16',
						className: 'bgm-lightblue'
					}, {
						title: 'Meet',
						start: '2014-06-12',
						end: '2014-06-12',
						className: 'bgm-green'
					}, {
						title: 'Lunch',
						start: '2014-06-12',
						className: 'bgm-cyan'
					}, {
						title: 'Birthday',
						start: '2014-06-13',
						className: 'bgm-amber'
					}, {
						title: 'Google',
						url: 'http://google.com/',
						start: '2014-06-28',
						className: 'bgm-amber'
					}
				]
			});
		})();
	}

	/*
     * Weather Widget
     */
	// if ($('#weather-widget')[0]) {
	//     $.simpleWeather({
	//         location: 'Austin, TX',
	//         woeid: '',
	//         unit: 'f',
	//         success: function(weather) {
	//             html = '<div class="weather-status">'+weather.temp+'&deg;'+weather.units.temp+'</div>';
	//             html += '<ul class="weather-info"><li>'+weather.city+', '+weather.region+'</li>';
	//             html += '<li class="currently">'+weather.currently+'</li></ul>';
	//             html += '<div class="weather-icon wi-'+weather.code+'"></div>';
	//             html += '<div class="dash-widget-footer"><div class="weather-list tomorrow">';
	//             html += '<span class="weather-list-icon wi-'+weather.forecast[2].code+'"></span><span>'+weather.forecast[1].high+'/'+weather.forecast[1].low+'</span><span>'+weather.forecast[1].text+'</span>';
	//             html += '</div>';
	//             html += '<div class="weather-list after-tomorrow">';
	//             html += '<span class="weather-list-icon wi-'+weather.forecast[2].code+'"></span><span>'+weather.forecast[2].high+'/'+weather.forecast[2].low+'</span><span>'+weather.forecast[2].text+'</span>';
	//             html += '</div></div>';
	//             $("#weather-widget").html(html);
	//         },
	//         error: function(error) {
	//             $("#weather-widget").html('<p>'+error+'</p>');
	//         }
	//     });
	// }

	/*
     * Todo Add new item
     */
	if ($('#todo-lists')[0]) {
		//Add Todo Item
		$('body').on('click', '#add-tl-item .add-new-item', function() {
			$(this).parent().addClass('toggled');
		});

		//Dismiss
		$('body').on('click', '.add-tl-actions > a', function(e) {
			e.preventDefault();
			var x = $(this).closest('#add-tl-item');
			var y = $(this).data('tl-action');

			if (y == "dismiss") {
				x.find('textarea').val('');
				x.removeClass('toggled');
			}

			if (y == "save") {
				x.find('textarea').val('');
				x.removeClass('toggled');
			}
		});
	}

	/*
     * Auto Hight Textarea
     */
	if ($('.auto-size')[0]) {
		autosize($('.auto-size'));
	}

	/*
    * Profile Menu
    */
	/*
    $('body').on('click', '.profile-menu > a', function(e){
        e.preventDefault();
        $(this).parent().toggleClass('toggled');
	    $(this).next().slideToggle(200);
    });
	//*/

	/*
     * Text Feild
     */

	//Add blue animated border and remove with condition when focus and blur
	if ($('.fg-line')[0]) {
		$('body').on('focus', '.fg-line .form-control', function() {
			$(this).closest('.fg-line').addClass('fg-toggled');
		})

		$('body').on('blur', '.form-control', function() {
			var p = $(this).closest('.form-group, .input-group');
			var i = p.find('.form-control').val();

			if (p.hasClass('fg-float')) {
				if (i.length == 0) {
					$(this).closest('.fg-line').removeClass('fg-toggled');
				}
			} else {
				$(this).closest('.fg-line').removeClass('fg-toggled');
			}
		});
	}

	//Add blue border for pre-valued fg-flot text feilds
	if ($('.fg-float')[0]) {
		$('.fg-float .form-control').each(function() {
			var i = $(this).val();

			if (!i.length == 0) {
				$(this).closest('.fg-line').addClass('fg-toggled');
			}

		});
	}

	/*
     * Audio and Video
     */
	if ($('audio, video')[0]) {
		$('video,audio').mediaelementplayer();
	}

	/*
     * Tag Select
     */
	if ($('.chosen')[0]) {
		$('.chosen').chosen({width: '100%', allow_single_deselect: true});
	}

	/*
     * Input Slider
     */
	//Basic
	if ($('.input-slider')[0]) {
		$('.input-slider').each(function() {
			var isStart = $(this).data('is-start');

			$(this).noUiSlider({
				start: isStart,
				range: {
					'min': 0,
					'max': 100
				}
			});
		});
	}

	//Range slider
	if ($('.input-slider-range')[0]) {
		$('.input-slider-range').noUiSlider({
			start: [
				30, 60
			],
			range: {
				'min': 0,
				'max': 100
			},
			connect: true
		});
	}

	//Range slider with value
	if ($('.input-slider-values')[0]) {
		$('.input-slider-values').noUiSlider({
			start: [
				45, 80
			],
			connect: true,
			direction: 'rtl',
			behaviour: 'tap-drag',
			range: {
				'min': 0,
				'max': 100
			}
		});

		$('.input-slider-values').Link('lower').to($('#value-lower'));
		$('.input-slider-values').Link('upper').to($('#value-upper'), 'html');
	}

	/*
     * Input Mask
     */
	if ($('input-mask')[0]) {
		$('.input-mask').mask();
	}

	/*
     * Color Picker
     */
	if ($('.color-picker')[0]) {
		$('.color-picker').each(function() {
			var colorOutput = $(this).closest('.cp-container').find('.cp-value');
			$(this).farbtastic(colorOutput);
		});
	}

	/*
     * HTML Editor
     */
	if ($('.html-editor')[0]) {
		$('.html-editor').summernote({height: 150});
	}

	if ($('.html-editor-click')[0]) {
		//Edit
		$('body').on('click', '.hec-button', function() {
			$('.html-editor-click').summernote({focus: true});
			$('.hec-save').show();
		})

		//Save
		$('body').on('click', '.hec-save', function() {
			$('.html-editor-click').code();
			$('.html-editor-click').destroy();
			$('.hec-save').hide();
			notify('Content Saved Successfully!', 'success');
		});
	}

	//Air Mode
	if ($('.html-editor-airmod')[0]) {
		$('.html-editor-airmod').summernote({airMode: true});
	}

	/*
     * Date Time Picker
     */

	//Date Time Picker
	// if ($('.date-time-picker')[0]) {
	// 	$('.date-time-picker').datetimepicker();
	// }
	//
	// //Time
	// if ($('.time-picker')[0]) {
	// 	$('.time-picker').datetimepicker({format: 'LT'});
	// }
	//
	// //Date
	// if ($('.date-picker')[0]) {
	// 	$('.date-picker').datetimepicker({format: 'DD/MM/YYYY'});
	// }

	/*
     * Form Wizard
     */

	// if ($('.form-wizard-basic')[0]) {
	// 	$('.form-wizard-basic').bootstrapWizard({
	// 	    tabClass: 'fw-nav',
	//         'nextSelector': '.next',
	//         'previousSelector': '.previous'
	// 	});
	// }

	/*
     * Bootstrap Growl - Notifications popups
     */
	function notify(message, type) {
		$.notify({
			message: message
		}, {
			type: type,
			allow_dismiss: false,
			label: 'Cancel',
			className: 'btn-xs btn-inverse',
			placement: {
				from: 'top',
				align: 'right'
			},
			delay: 2500,
			animate: {
				enter: 'animated bounceIn',
				exit: 'animated bounceOut'
			},
			offset: {
				x: 20,
				y: 85
			}
		});
	}

	/*
     * Waves Animation
     */
	(function() {
		Waves.attach('.btn:not(.btn-icon):not(.btn-float):not(.btn-hinted)');
		Waves.attach('.btn-icon, .btn-float', ['waves-circle', 'waves-float']);
		Waves.init();
	})();

	/*
     * Lightbox
     */
	// if ($('.lightbox')[0]) {
	// 	$('.lightbox').lightGallery({enableTouch: true});
	// }

	/*
     * Link prevent
     */
	$('body').on('click', '.a-prevent', function(e) {
		e.preventDefault();
	});

	/*
     * Collaspe Fix
     */
	if ($('.collapse')[0]) {

		//Add active class for opened items
		$('.collapse').on('show.bs.collapse', function(e) {
			$(this).closest('.panel').find('.panel-heading').addClass('active');
		});

		$('.collapse').on('hide.bs.collapse', function(e) {
			$(this).closest('.panel').find('.panel-heading').removeClass('active');
		});

		//Add active class for pre opened items
		$('.collapse.in').each(function() {
			$(this).closest('.panel').find('.panel-heading').addClass('active');
		});
	}

	/*
     * Tooltips
     */
	if ($('[data-toggle="tooltip"]')[0]) {
		$('[data-toggle="tooltip"]').tooltip();
	}

	/*
     * Popover
     */
	if ($('[data-toggle="popover"]')[0]) {
		$('[data-toggle="popover"]').popover();
	}

	/*
     * Message
     */

	//Actions
	if ($('.on-select')[0]) {
		var checkboxes = '.lv-avatar-content input:checkbox';
		var actions = $('.on-select').closest('.lv-actions');

		$('body').on('click', checkboxes, function() {
			if ($(checkboxes + ':checked')[0]) {
				actions.addClass('toggled');
			} else {
				actions.removeClass('toggled');
			}
		});
	}

	if ($('#ms-menu-trigger')[0]) {
		$('body').on('click', '#ms-menu-trigger', function(e) {
			e.preventDefault();
			$(this).toggleClass('open');
			$('.ms-menu').toggleClass('toggled');
		});
	}

	/*
     * Login
     */
	if ($('.login-content')[0]) {
		//Add class to HTML. This is used to center align the logn box
		$('html').addClass('login-content');

		$('body').on('click', '.login-navigation > li', function() {
			var z = $(this).data('block');
			var t = $(this).closest('.lc-block');

			t.removeClass('toggled');

			setTimeout(function() {
				$(z).addClass('toggled');
			});

		})
	}

	/*
     * Fullscreen Browsing
     */
	if ($('[data-action="fullscreen"]')[0]) {
		var fs = $("[data-action='fullscreen']");
		fs.on('click', function(e) {
			e.preventDefault();

			//Launch
			function launchIntoFullscreen(element) {

				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.mozRequestFullScreen) {
					element.mozRequestFullScreen();
				} else if (element.webkitRequestFullscreen) {
					element.webkitRequestFullscreen();
				} else if (element.msRequestFullscreen) {
					element.msRequestFullscreen();
				}
			}

			//Exit
			function exitFullscreen() {

				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				}
			}

			launchIntoFullscreen(document.documentElement);
			fs.closest('.dropdown').removeClass('open');
		});
	}

	/*
     * Clear Local Storage
     */
	if ($('[data-action="clear-localstorage"]')[0]) {
		var cls = $('[data-action="clear-localstorage"]');

		cls.on('click', function(e) {
			e.preventDefault();

			swal({
				title: "Are you sure?",
				text: "All your saved localStorage values will be removed",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#DD6B55",
				confirmButtonText: "Yes, delete it!",
				closeOnConfirm: false
			}, function() {
				localStorage.clear();
				swal("Done!", "localStorage is cleared", "success");
			});
		});
	}

	/*
     * Profile Edit Toggle
     */
	if ($('[data-pmb-action]')[0]) {
		$('body').on('click', '[data-pmb-action]', function(e) {
			e.preventDefault();
			var d = $(this).data('pmb-action');

			if (d === "edit") {
				$(this).closest('.pmb-block').toggleClass('toggled');
			}

			if (d === "reset") {
				$(this).closest('.pmb-block').removeClass('toggled');
			}

		});
	}

	/*
     * IE 9 Placeholder
     */
	if ($('html').hasClass('ie9')) {
		$('input, textarea').placeholder({customClass: 'ie9-placeholder'});
	}

	/*
     * Print
     */
	if ($('[data-action="print"]')[0]) {
		$('body').on('click', '[data-action="print"]', function(e) {
			e.preventDefault();

			window.print();
		})
	}

	/*
     * Typeahead Auto Complete
     */
	if ($('.typeahead')[0]) {

		var statesArray = [
			'Alabama',
			'Alaska',
			'Arizona',
			'Arkansas',
			'California',
			'Colorado',
			'Connecticut',
			'Delaware',
			'Florida',
			'Georgia',
			'Hawaii',
			'Idaho',
			'Illinois',
			'Indiana',
			'Iowa',
			'Kansas',
			'Kentucky',
			'Louisiana',
			'Maine',
			'Maryland',
			'Massachusetts',
			'Michigan',
			'Minnesota',
			'Mississippi',
			'Missouri',
			'Montana',
			'Nebraska',
			'Nevada',
			'New Hampshire',
			'New Jersey',
			'New Mexico',
			'New York',
			'North Carolina',
			'North Dakota',
			'Ohio',
			'Oklahoma',
			'Oregon',
			'Pennsylvania',
			'Rhode Island',
			'South Carolina',
			'South Dakota',
			'Tennessee',
			'Texas',
			'Utah',
			'Vermont',
			'Virginia',
			'Washington',
			'West Virginia',
			'Wisconsin',
			'Wyoming'
		];
		var states = new Bloodhound({datumTokenizer: Bloodhound.tokenizers.whitespace, queryTokenizer: Bloodhound.tokenizers.whitespace, local: statesArray});

		$('.typeahead').typeahead({
			hint: true,
			highlight: true,
			minLength: 1
		}, {
			name: 'states',
			source: states
		});
	}

	/*
     * Wall
     */
	if ($('.wcc-toggle')[0]) {
		var z = '<div class="wcc-inner">' +
		'<textarea class="wcci-text auto-size" placeholder="Write Something..."></textarea>' +
		'</div>' +
		'<div class="m-t-15">' +
		'<button class="btn btn-sm btn-primary">Post</button>' +
		'<button class="btn btn-sm btn-link wcc-cencel">Cancel</button>' +
		'</div>'

		$('body').on('click', '.wcc-toggle', function() {
			$(this).parent().html(z);
			autosize($('.auto-size')); //Reload Auto size textarea
		});

		//Cancel
		$('body').on('click', '.wcc-cencel', function(e) {
			e.preventDefault();

			$(this).closest('.wc-comment').find('.wcc-inner').addClass('wcc-toggle').html('Write Something...')
		});

	}

	/*
     * Skin Change
     */
	$('body').on('click', '[data-skin]', function() {
		var currentSkin = $('[data-current-skin]').data('current-skin');
		var skin = $(this).data('skin');

		$('[data-current-skin]').attr('data-current-skin', skin)

	});

});
